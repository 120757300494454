
<template>
  <div class="row gy-2">
    <div class="col-12">
      <div class="row mb-2 gy-2">
        <div class="col-sm-12 col-md-6 d-flex mb-2 mb-md-0">
          <div id="tickets-table_length" class="dataTables_length">
            <label class="d-inline-flex mb-0 align-items-center">
              <b-form-select class="form-select" v-model="paginate.perPage" :options="pageOptions"></b-form-select>
            </label>
          </div>
        </div>
        <div class="col-sm-12 col-md-6">
          <label class="mb-0 w-100 align-items-center">
            <b-form-input v-model="searchQuery" type="search" placeholder="Search language..." class="form-control"></b-form-input>
          </label>
        </div>
      </div>
      <div class="card">
        <div class="card-body">
          <div class="table-responsive mb-0">
            <b-table striped hover table-class="table table-borderless table-hover table-centered m-0" thead-tr-class=""
               ref="selectableTable" :items="paginatedItems"
               :fields="columns" responsive="sm">
                  <template v-slot:cell(number)="data">
                    <div style="min-width:6rem;">{{data.item.number}}</div>
                  </template>
                  <template v-slot:cell(language)="data">
                    <div style="min-width:6rem;">{{data.item.group.name}}</div>
                  </template>
                  <template v-slot:cell(amount)="data">
                    <span> ${{data.item.amount | money_format}} </span>
                  </template>
                  <template v-slot:cell(billing_period)="data">
                    <div style="min-width:6rem;"> {{data.item.billing_period}}ly </div>
                  </template>
                  <template v-slot:cell(current_period_end)="data">
                    <div style="min-width:6rem;"> {{data.item.current_period_end | date_time(1)}} </div>
                  </template>
                  <template v-slot:cell(status)="data">
                    <div><span v-html="statusBadge(data.item.status)"> </span></div>
                  </template>
                  <template v-slot:cell(action)="data">
                    <router-link :to="`/subscriptions/${data.item.id}`" class="">
                      view</router-link>
                  </template>
            </b-table>
          </div>
          <div class="row">
            <div class="col">
              <ul class="pagination pagination-rounded">
                <b-pagination v-model="paginate.currentPage" :limit="paginate.limit" 
                :total-rows="searchedItems.length" :per-page="paginate.perPage"></b-pagination>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "subscription-list",
  data() {
    return {
      paginate:{
        currentPage: 1,
        perPage: 10,
        limit:3
      },
      pageOptions: [10, 25, 50, 100],
      searchQuery: '',
      selected: [],
      isSelectAll: false,
      columns: [
          {
              key: "number",
              label: "Number"
          },
          {
            key: "language",
            label: "Language",
          },
          {
            key: "amount",
            label: "Amount",
          },
          {
            key: "billing_period",
            label: "Period",
          },
          {
            key: "current_period_end",
            label: "Period end",
          },
          {
            key: "status",
            label: "Status",
          },
          "action"
      ],
    }
  },
  computed: {
    subscriptions(){
      return this.$store.state.subscriptions
    },
    searchedItems(){
      return this.subscriptions.filter(item => {
        if (this.searchQuery) {
          return item.group?.name.toLowerCase().includes(this.searchQuery.toLowerCase());
        } else {
          return true;
        }
      });
    },
    paginatedItems(){
        return this.searchedItems.slice(
          this.paginateStart,
          this.paginateEnd
        );
    },
    paginateStart(){
      return (this.paginate.currentPage - 1) * this.paginate.perPage;
    },
    paginateEnd(){
      return (this.paginate.currentPage - 1) * this.paginate.perPage + this.paginate.perPage;
    },
  },
  methods: {
    selectAllRows() {
      this.isSelectAll = !this.isSelectAll
      if(this.isSelectAll){
        this.selected = this.subscriptions.map(o => o.id );
      }else{
        this.selected = []
      }
    },
    fetchSubscriptions() {
      this.$store.dispatch("fetchSubscriptions")
    },
  },
  created(){
    this.$store.dispatch("fetchSubscriptions")
  },
}

</script>

